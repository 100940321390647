export default [
    {
        path: 'supply',
        name: 'supply',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/pages/credit/Supply.vue'),
    }
]
