import acquiring from './lk/acquiring'
import credit from './lk/credit'
import pages from './lk/pages'
import system from './lk/system'
import wallet from './lk/wallet'

export default [
    ...wallet, 
    ...credit, 
    ...pages, 
    ...acquiring,
    ...system,
    {
        path: 'tasks',
        name: 'tasks',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/Tasks.vue'),
    },
    {
        path: 'account',
        name: 'account',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/Account.vue'),
    },
    {
        path: 'invites',
        name: 'invites',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/Invites.vue'),
    }
]