import Vue from 'vue'
import VueRouter from 'vue-router'
// Routes
import i18n from "@/libs/i18n"
import { redirectPath } from '@/libs/ton/utils'
import protocol from './protocol/index'

const children = [...protocol];

const routes = [
            {
                path: "/:locale(ru|en)?",
                redirect: "/wallet",
            },
            {
                path: "/:locale(ru|en)?/",
                component: {
                    render: h => h('router-view'),
                },

                children: children
            },
            {
                path: "*",
                redirect: "/wallet",
            },

        ]
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onComplete, onAbort) {
    const result = originalPush.call(this, location, onComplete, onAbort)
    if (result) {

        if (result) {
            return result.catch(err => {
                console.warn(`consumed router exception: ${err}`)
            })
        }
    }
    return result
}

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes,
})

router.beforeEach(async (to, _, next) => {
    if (
        localStorage.getItem('onboardingFinished') &&
        to.name == 'onboarding'
    ) {
        console.log(router)
        return window.location.replace(process.env.APP_BASE_URL + `/${redirectPath()}`);
    }
    if (to.query.chat_id) {
        if (!localStorage.getItem('chatId')) {
            localStorage.setItem('chatId', to.query.chat_id)
        }
    }
    if (to.query.allow_web) {
        if (to.query.allow_web === 'true') {
            localStorage.setItem('allowWeb', to.query.allow_web)
        } else if (to.query.allow_web === 'false') {
            localStorage.removeItem('allowWeb')
        }
    } else {
        localStorage.setItem('allowWeb', false)
    }
    if (to.query.lang && localStorage.getItem('lang') != to.query.lang) {
        const updatedLang = to.query.lang == 'ru' ? 'ru' : 'en'
        document.cookie = 'lang=' + updatedLang
        localStorage.setItem('lang', updatedLang)
        i18n.locale = updatedLang
    }

    return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {

    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
