import axiosIns from '@/libs/axios'

export default class User {
    static async show(fromCache = true) {
        if (fromCache && localStorage.getItem('main')) {
            return JSON.parse(localStorage.getItem('main'))
        }

        const resp = await axiosIns.get(`/user`)

        if (!resp.data.user) {
            return null
        }

        // Обновляем main
        const main = {
            name: resp.data.user.telegram.name,
            username: resp.data.user.telegram.username,
            isPremium: resp.data.user.telegram.is_premium,
            tasks: resp.data.user.tasks,
            wallet: resp.data.user.wallet,
            userPosition: resp.data.user.userPosition,
            invite: resp.data.user.invite,
            acquiring: resp.data.user.acquiring,
        }
        localStorage.setItem('main', JSON.stringify(main))
        User.checkTasks(resp.data.user.tasks)
        return main
    }

    static checkTasks(tasks) {
        let hasUnclaimedTask = false
        for (const task of tasks) {
            if (task.status == 'claim') {
                hasUnclaimedTask = true
                break
            }
        }
        localStorage.setItem('hasUnclaimedTask', hasUnclaimedTask)
    }
}
