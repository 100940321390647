import axiosIns from '@/libs/axios'

export default class Auth {
    static async login() {
        const chatId = localStorage.getItem('chatId')

        if (!chatId) return

        const resp = await axiosIns.get(`/auth?chat_id=${chatId}`)
        console.log(resp)

        if (!resp.data.user) {
            return null
        }

        // Обновляем main
        const main = {
            name: resp.data.user.telegram.name,
            username: resp.data.user.telegram.username,
            isPremium: resp.data.user.telegram.is_premium,
            tasks: resp.data.user.tasks,
            wallet: resp.data.user.wallet,
            userPosition: resp.data.user.userPosition,
            invite: resp.data.user.invite,
            acquiring: resp.data.user.acquiring,
        }
        localStorage.setItem('main', JSON.stringify(main))
        return main
    }

}
