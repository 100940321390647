<template>
    <div id="app" class="app-wrapper dark-layout" :class="[skinClasses]">
        <component :is="layout" v-if="!isUserLoggedIn()">
            <router-view />
        </component>

        <component :is="layout" v-else>
            <router-view />
        </component>

        <!-- <scroll-to-top v-if="enableScrollToTop" /> -->
    </div>
</template>

<script>

import Auth from '@/api/v2/auth'
import { isUserLoggedIn } from '@/auth/utils'
import store from '@/store'
import useAppConfig from '@core/app-config/useAppConfig'
import { on } from '@telegram-apps/sdk-vue'
import { $themeBreakpoints, $themeColors, $themeConfig } from '@themeConfig'
import { useCssVar, useWindowSize } from '@vueuse/core'
import { watch } from 'vue'
const LayoutCommonPages = () => import('@/layouts/LayoutCommonPages.vue')
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () =>
    import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')
const LayoutGame = () => import('@/layouts/LayoutGame.vue')

export default {
    data() {
        return {
            chatId: null,
        }
    },
    components: {
        // Layouts
        LayoutHorizontal,
        LayoutVertical,
        LayoutFull,
        //
        LayoutCommonPages,
        LayoutGame,

        // ScrollToTop,
    },
    setup() {
        const { skin, skinClasses } = useAppConfig()
        const { enableScrollToTop } = $themeConfig.layout
        // If skin is dark when initialized => Add class to body
        if (skin.value === 'dark') document.body.classList.add('dark-layout')
        // Set Window Width in store
        store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
        const { width: windowWidth } = useWindowSize()
        watch(windowWidth, (val) => {
            store.commit('app/UPDATE_WINDOW_WIDTH', val)
        })

        return {
            skinClasses,
            enableScrollToTop,
            isUserLoggedIn,
        }
    },

    async created() {
        // В случае потери chatId смотрим есть ли данные от предоставляемого telegram объекта и выполняем вход
        if (!localStorage.chatId && localStorage['tg-main']) {
            const tgMain = JSON.parse(localStorage['tg-main'])
            if (tgMain.id) {
                localStorage.chatId = tgMain.id
                await Auth.login()
            }
        }

        const hash = window.location.hash.slice(1)
        const params = new URLSearchParams(hash)
        this.hash = params.get('tgWebAppFullscreen')
        // Определяем сеть
        if (!localStorage.network) {
            localStorage.network =
                process.env.NODE_ENV == 'production' ? 'mainnet' : 'testnet'
        }
        on('content_safe_area_changed', (payload) => {
            store.commit('appConfig/UPDATE_SAFE_AREA', payload)
        })

        if (hash) {
            // const params = new URLSearchParams(hash)
            store.commit(
                'appConfig/UPDATE_IS_FULLSCREEN',
                params.get('tgWebAppFullscreen')
            )
        }

        //Deprecated speed optimization
        // if (!localStorage.expireDate) {
        //     const wallet = await getWallet()
        //     if (wallet) {
        //         await Wallet.updateBalance()
        //     }
        // }
    },

    watch: {
        // Регистрируем пользователя сразу при полученеии chatId
        '$route.query.chat_id': {
            handler: async (chatId) => {
                if (chatId) {
                    if (!localStorage.main) {
                        localStorage.chatId = chatId
                        await Auth.login()
                    }
                }
            },
            deep: true,
            immediate: true,
        },
    },

    // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
    // Currently, router.currentRoute is not reactive and doesn't trigger any change
    computed: {
        layout() {
            if (this.$route.name == null) {
                return null
            }
            if (this.$route.meta.layout === 'common')
                return 'layout-common-pages'
            if (this.$route.meta.layout === 'full') return 'layout-full'
            if (this.$route.meta.layout === 'game') return 'layout-game'

            return `layout-${this.contentLayoutType}`
        },
        contentLayoutType() {
            return this.$store.state.appConfig.layout.type
        },
    },

    beforeCreate() {
        // Set colors in theme
        const colors = [
            'primary',
            'secondary',
            'success',
            'info',
            'warning',
            'danger',
            'light',
            'dark',
        ]

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = colors.length; i < len; i++) {
            $themeColors[colors[i]] = useCssVar(
                `--${colors[i]}`,
                document.documentElement
            ).value.trim()
        }

        // Set Theme Breakpoints
        const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = breakpoints.length; i < len; i++) {
            $themeBreakpoints[breakpoints[i]] = Number(
                useCssVar(
                    `--breakpoint-${breakpoints[i]}`,
                    document.documentElement
                ).value.slice(0, -2)
            )
        }

        // Set RTL
        const { isRTL } = $themeConfig.layout
        document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
    },
}
</script>
<style lang="scss">
.app-wrapper {
    height: 100vh;
}
.onboarding-blackout {
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 2;
    }
}
.onboarding-highlighting {
    z-index: 3 !important;
}
</style>
