export default [
    {
        path: 'acquiring',
        name: 'acquiring',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Input form',
        },
        component: () => import('@/views/pages/acquiring/Acquiring.vue'),
    },
    {
        path: 'acquiring/payment/:uuid',
        name: 'acquiring-payment',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Input form',
        },
        component: () => import('@/views/pages/acquiring/AcquiringPayment.vue'),
    },
    {
        path: 'transactions',
        name: 'transactions',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/pages/acquiring/Transactions.vue'),
    },
]
