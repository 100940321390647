export default [
    {
        path: 'payment-success',
        name: 'payment-success',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/pages/system/PaymentSuccess.vue'),
    },
    {
        path: 'payment-error',
        name: 'payment-error',
        meta: {
            resource: 'CommonPages',
            layout: 'game',
            hide: true,
            sitemap: { ignoreRoute: true },
            pageTitle: 'Runegame',
        },
        component: () => import('@/views/pages/system/PaymentError.vue'),
    },
]
