import i18n from '@/libs/i18n'
import { postEvent } from '@telegram-apps/bridge'
import { expandViewport, init, isTMA, retrieveLaunchParams } from '@telegram-apps/sdk-vue'
import PortalVue from 'portal-vue'
import Vue from 'vue'
import App from './App.vue'
import './global-components'
import router from './router'
import store from './store'

// 3rd party plugins
import '@/libs/acl'
import '@axios'

// Connect fonts
import '@core/assets/fonts/ibm_plex_mono/iconfont.css'
import '@core/assets/fonts/inter/iconfont.css'
import '@core/assets/fonts/montserrat/iconfont.css'

import '@core/scss/core.scss'
declare global {
    interface Window {
        Telegram: any;
}
}
function setTgMain(newValue: Object) {
    const now = Date.now();

    const lastUpdate = Number(localStorage.getItem('last-tg-main-update') ?? now.toString());
    const tenMinutes = 10 * 60 * 1000;
    // const oneDay = 24 * 60 * 60 * 1000;

    if (now - lastUpdate > tenMinutes) {
        localStorage['tg-main'] = JSON.stringify(newValue)
        localStorage.setItem('last-tg-main-update', now.toString());
    }
}
if (await isTMA()) {
    init()
    try {
        const launchParams = retrieveLaunchParams()
        if (launchParams) {
            setTgMain({
                id: launchParams?.tgWebAppData?.user?.id,
                is_premium: launchParams?.tgWebAppData?.user?.is_premium
            })
        }

    } catch (e) {
        console.error('Failed to retrieve launch params:', e)
    }
    if (expandViewport.isAvailable()) {
        expandViewport()
        postEvent('web_app_request_fullscreen')
        postEvent('web_app_toggle_orientation_lock', {locked: true})
        
    }
    console.log("It's Telegram Mini Apps")
}


Vue.use(PortalVue)
// Vue.use(VueReCaptcha, {
//     siteKey: process.env.RECAPCHA_SITE_KEY,
//     loaderOptions: {
//         autoHideBadge: true,
//     },
// })
// Vue.use(VueCarousel)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
