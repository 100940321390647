import Auth from '@/api/v2/auth'

import router from '@/router'
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.API_URL + '/api/v1',
    // timeout: 1000,
    withCredentials: true,
})

axiosIns.interceptors.request.use(
   async  (config) => {
 
        const token = sessionStorage.getItem('token')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        } else if (!config.url.includes('auth')) {
            await Auth.login()
        }
        return config
    },
    (error) => Promise.reject(error)
)

axiosIns.interceptors.response.use(
    async (response) => {
        if (response.data.statusCode === 500 && response.data?.message === "Unauthenticated") {
            const lastAuth = localStorage.lastAuth ? Number(localStorage.lastAuth) : Date.now()
            if (!localStorage.lastAuth) {
                localStorage.lastAuth = Date.now()
                await User.login(false)
                const res  = await axiosIns[response.config.method](response.config.url, {params: response.config.params})
                return res
            } else if ((Date.now() - lastAuth) > 600000) {
                localStorage.lastAuth = Date.now()
                await User.login(false)
                const res  = await axiosIns[response.config.method](response.config.url, {params: response.config.params})
                return res
            } else {
                return response
            }
        }
        if (response?.data?.token) {
            sessionStorage.setItem('token', response.data.token)
        }
        return response
    },
    (error) => {
        if (error?.response?.status === 401) {
            localStorage.clear()
            if (this != null && this.$router != null) {
                router.push({ name: 'wallet' })
            }
        }
        return error
    }
)

Vue.prototype.$http = axiosIns

export default axiosIns
