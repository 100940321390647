export const initialAbility = [
    {
        action: 'manage',
        subject: 'CommonPages',
    },
    {
        action: 'manage',
        subject: 'Lk',
    },

]
export const _ = undefined
